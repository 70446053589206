// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bacalhau-js": () => import("./../../../src/pages/bacalhau.js" /* webpackChunkName: "component---src-pages-bacalhau-js" */),
  "component---src-pages-corvina-js": () => import("./../../../src/pages/corvina.js" /* webpackChunkName: "component---src-pages-corvina-js" */),
  "component---src-pages-filete-salmao-js": () => import("./../../../src/pages/filete-salmao.js" /* webpackChunkName: "component---src-pages-filete-salmao-js" */),
  "component---src-pages-finissimos-salmao-js": () => import("./../../../src/pages/finissimos-salmao.js" /* webpackChunkName: "component---src-pages-finissimos-salmao-js" */),
  "component---src-pages-gravlax-salmao-js": () => import("./../../../src/pages/gravlax-salmao.js" /* webpackChunkName: "component---src-pages-gravlax-salmao-js" */),
  "component---src-pages-hamburguer-atum-js": () => import("./../../../src/pages/hamburguer-atum.js" /* webpackChunkName: "component---src-pages-hamburguer-atum-js" */),
  "component---src-pages-hamburguer-bacalhau-js": () => import("./../../../src/pages/hamburguer-bacalhau.js" /* webpackChunkName: "component---src-pages-hamburguer-bacalhau-js" */),
  "component---src-pages-hamburguer-salmao-chili-lemon-js": () => import("./../../../src/pages/hamburguer-salmao-chili-lemon.js" /* webpackChunkName: "component---src-pages-hamburguer-salmao-chili-lemon-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medalhoes-salmao-js": () => import("./../../../src/pages/medalhoes-salmao.js" /* webpackChunkName: "component---src-pages-medalhoes-salmao-js" */),
  "component---src-pages-posta-salmao-js": () => import("./../../../src/pages/posta-salmao.js" /* webpackChunkName: "component---src-pages-posta-salmao-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

