module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"peixe-facil","short_name":"peixe-facil","start_url":"/","background_color":"#FDFAF7","theme_color":"#FDFAF7","display":"minimal-ui","icon":"src/images/logo-new.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4ada6b886fb3814bd910b7b431f02484"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
