/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
const $ = require("jquery")

export const onRouteUpdate = () => {
  

   
       
          // If a link has a dropdown, add sub menu toggle.
          $('nav ul li a:not(:only-child)').click(function(e) {
            
            $(this).siblings('.nav-dropdown').toggle();
            // Close one dropdown when selecting another
            $('.nav-dropdown').not($(this).siblings()).hide();
            e.stopPropagation();
            
          
          });
          // Clicking away from dropdown will remove the dropdown class
          $('html').click(function() {
            $('.nav-dropdown').hide();
          });

          $('nav ul li a').click(function(){
            $('nav ul').slideToggle();
            document.getElementById('nav-toggle').classList.toggle('active');


          });
          // Toggle open and close nav styles on click
          $('#nav-toggle').click(function() {
            $('nav ul').slideToggle();
            $('nav').css('borderBottom','solid 1px #e4b68d');



          });
          $('html').click(function() {
            $('.nav-dropdown').hide();
          });
          // Hamburger to X toggle
          $('#nav-toggle').on('click', function() {
            this.classList.toggle('active');
          });
      
    
}